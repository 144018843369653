import {Card, IconButton} from '@mui/material'
import {useState} from 'react'
import DataGridWrapper from '../grid/DataGridWrapperNew'
import {
  GridColDef,
  GridFilterModel,
  GridLogicOperator
} from '@mui/x-data-grid-pro'
import {useMainStore} from '../../contexts/Main'
import Payment from '../../entities/payment'
import LabelCol from '../grid/columnTypes/LabelCol'
import {observer} from 'mobx-react-lite'
import {format} from 'date-fns'
import InstitutionsToolbar from 'src/components/Institutions/InstitutionsToolbar'
import {singleSelectFilterFunc} from 'src/components/ui/SingleSelectFilter'
import {LabelColor} from 'src/components/label'

import {ReactComponent as DeleteIcon} from 'src/images/delete.svg'
import DeletePaymentDialog from './DeletePaymentDialog'

const PaymentsGrid = observer(() => {
  const {payments, paymentsStatuses, deletePayment} =
    useMainStore().paymentsStore
  const {currentInstitution} = useMainStore().institutionStore
  const {isManager} = useMainStore().loginStore
  const {users} = useMainStore().usersStore

  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [deleteRowId, setDeleteRowId] = useState(null)

  const onSave = async () => {
    deleteRowId && (await deletePayment(deleteRowId))
    setOpenDialog(false)
    setDeleteRowId(null)
  }

  const handleDeleteClick = (row: any) => {
    const id = row?.id
    if (id) {
      setDeleteRowId(id)
      setOpenDialog(true)
    }
  }

  const onClose = () => {
    setOpenDialog(false)
    setDeleteRowId(null)
  }

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: GridLogicOperator.And
  } as GridFilterModel)

  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null)

  const currentPayments: Payment[] =
    payments
      ?.filter(p => p.institutionId === currentInstitution?.id)
      .reverse() || []

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'תאריך ההחלטה',
      headerAlign: 'center',
      width: 120,
      type: 'date',
      align: 'center',
      flex: 1
    },

    {
      field: 'amount',
      headerName: 'סכום',
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      flex: 1
    },
    {
      field: 'month',
      headerName: 'חודש החלוקה',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      flex: 1
    },
    {
      field: 'year',
      headerName: 'שנה',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      flex: 1
    },
    {
      field: 'paymentDate',
      headerName: 'תאריך תשלום',
      flex: 1,
      type: 'date',
      align: 'center',
      headerAlign: 'center',
      valueGetter: (_, row) => {
        const payDate = row?.paymentReport?.payDate
        return payDate ? new Date(payDate) : null // Return a Date object or null
      },
      valueFormatter: (_, row) => {
        const payDate = row?.paymentReport?.payDate
        if (!payDate) return ''
        const date = new Date(payDate)
        return format(date, 'dd.MM.yy')
      }
    },
    {
      field: 'createdBy',
      headerName: 'הוחלט ע”י',
      headerAlign: 'center',
      minWidth: 140,
      sortable: true,
      filterable: true,
      type: 'string',
      align: 'center',
      flex: 1,

      valueGetter: (_, row) => {
        return users?.find(u => u.id === row?.createdBy)?.fullName || ''
      }
    },
    {
      field: 'statusId',
      headerName: 'סטטוס',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      valueFormatter: (params: any) => {
        return params.description
      },
      renderCell: (params: any) => {
        const status = paymentsStatuses?.find(v => v.id === params.row.statusId)
        return (
          <LabelCol
            text={status?.description || ''}
            color={(status?.color || 'primary') as LabelColor}
          />
        )
      },
      type: 'singleSelect',
      filterOperators: singleSelectFilterFunc(paymentsStatuses)
    },

    {
      field: 'comment',
      headerName: 'הערות',
      align: 'center',
      headerAlign: 'center',
      flex: 1
    }
  ]

  if (isManager) {
    columns.push({
      field: 'delete',
      headerName: '',
      filterable: false,
      headerAlign: 'center',
      width: 150,
      align: 'center',
      flex: 1,
      renderCell: (params: any) => {
        return params.row.statusId === 1 || params.row.statusId === 3 ? (
          <IconButton onClick={() => handleDeleteClick(params.row)}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <></>
        )
      }
    })
  }

  return (
    <>
      <Card sx={{minHeight: '100%', p: 2, m: 1.5}}>
        {paymentsStatuses && payments && payments.length > 0 && (
          <DataGridWrapper
            columns={columns}
            rows={currentPayments.map(row => ({...row, id: row.id})) || []}
            hideFooter
            disableColumnMenu
            filterModel={filterModel}
            onFilterModelChange={(newValue: GridFilterModel) => {
              setFilterModel(newValue)
            }}
            slots={{
              toolbar: InstitutionsToolbar
            }}
            slotProps={{
              panel: {
                anchorEl: filterButtonEl,
                placement: 'bottom'
              },
              baseSelect: {native: false, defaultValue: ''},
              toolbar: {
                setFilterButtonEl: setFilterButtonEl,
                setFilterModel: setFilterModel
              },
              filterPanel: {
                filterFormProps: {
                  logicOperatorInputProps: {
                    variant: 'outlined',
                    size: 'small'
                  },
                  columnInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: {mt: 'auto'}
                  },
                  operatorInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: {mt: 'auto'}
                  },
                  valueInputProps: {
                    InputComponentProps: {
                      variant: 'outlined',
                      size: 'small'
                    }
                  }
                },
                sx: {
                  '& .MuiDataGrid-panelFooter': {
                    justifyContent: 'flex-start',
                    px: 2.5
                  },
                  '& .MuiDataGrid-panelFooter .MuiButton-root:last-of-type': {
                    color: (theme: any) => theme.palette.primary.main,
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 171, 85, 0.08)'
                    }
                  },
                  '& .MuiDataGrid-filterForm': {p: 2},
                  '& .MuiDataGrid-filterFormLogicOperatorInput': {
                    mr: 1,
                    width: 75
                  },
                  '& .MuiDataGrid-filterFormColumnInput': {mr: 1, width: 150},
                  '& .MuiDataGrid-filterFormOperatorInput': {
                    mr: 1,
                    width: 150
                  },
                  '& .MuiDataGrid-filterFormValueInput': {width: 280},
                  '& .MuiDataGrid-filterFormDeleteIcon': {
                    justifyContent: 'center'
                  },
                  '& .MuiDataGrid-main': {
                    direction: 'rtl',
                    overflowX: 'auto'
                  },
                  '& .MuiDataGrid-scrollbar': {right: 'unset', left: '0'},
                  '& .MuiDataGrid-virtualScroller': {direction: 'ltr'}
                }
              }
            }}
          />
        )}
      </Card>
      {openDialog && <DeletePaymentDialog onClose={onClose} onSave={onSave} />}
    </>
  )
})

export default PaymentsGrid
