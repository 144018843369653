import {computed, makeAutoObservable} from 'mobx'

export class BaseOnResource {
  month: number

  monthString: string

  year: number

  calcPayAmount: number

  isDefault: boolean

  constructor(baseOn: any) {
    this.month = baseOn.Month
    this.monthString = baseOn.MonthString
    this.year = baseOn.Year
    this.calcPayAmount = baseOn.CalcPayAmount
    this.isDefault = baseOn.IsDefault

    makeAutoObservable(this)
  }
}

export class CalcPayment {
  id: number

  institutionId: number

  month: number

  monthString: string

  year: number

  baseOnMonths: BaseOnResource[]

  paymentAmount: number

  allPayments: CalcPayment[]

  constructor(calcPayment: any, allPayments: CalcPayment[]) {
    this.id = calcPayment.Id
    this.institutionId = calcPayment.InstitutionId
    this.month = calcPayment.Month
    this.monthString = calcPayment.MonthString
    this.year = calcPayment.Year
    this.baseOnMonths = calcPayment.BaseOnMonths.map(
      (baseOn: any) => new BaseOnResource(baseOn)
    )
    this.paymentAmount = calcPayment.PaymentAmount
    this.allPayments = allPayments

    makeAutoObservable(this, {
      defaultMonth: computed
    })
  }

  get defaultMonth() {
    return this.baseOnMonths.find(baseOn => baseOn.isDefault)
  }

  get calcPayAmountForDefaultMonth() {
    return this.defaultMonth?.calcPayAmount || 0
  }

  get totalCalcPayAmountPreviousMonths() {
    if (this.paymentAmount === 0) {
      return this.allPayments
        .filter(p => p.id < this.id)
        .reduce((sum, p) => sum + (p.defaultMonth?.calcPayAmount || 0), 0)
    }
    return undefined
  }

  get totalPaidPreviousMonths() {
    return this.allPayments
      .filter(p => p.id < this.id)
      .reduce((sum, p) => sum + p.paymentAmount, 0)
  }

  get differences() {
    if (this.paymentAmount !== 0 || !this.totalCalcPayAmountPreviousMonths)
      return undefined
    return this.totalCalcPayAmountPreviousMonths - this.totalPaidPreviousMonths
  }

  get totalPaymentWithDifferences() {
    if (!this.differences) return undefined
    return this.calcPayAmountForDefaultMonth + this.differences
  }

  setAllpayments = (allPayments: CalcPayment[]) => {
    this.allPayments = allPayments
  }
}
