import {makeAutoObservable} from 'mobx'

export default class Dashboard {
  institutionId: number

  allCount?: number

  kollelHalfDayCount?: number

  yeshivaCount?: number

  kollelFullDayCount?: number

  payAllCount?: number

  payKollelHalfDayCount?: number

  payYeshivaCount?: number

  payKollelFullDayCount?: number

  rejectCount?: number

  duplicateCount?: number

  payAmount?: number

  kollelHalfDayRate?: number

  yeshivaRate?: number

  kollelFullDayRate?: number

  payMonth?: string

  isMatchTeam?: boolean

  notMatchKollelHalfDayCount?: number

  notMatchYeshivaCount?: number

  notMatchKollelFullDayCount?: number

  prevDifferences?: number

  constructor(dashboard: any) {
    this.institutionId = dashboard.InstitutionId
    this.allCount = dashboard.AllCount
    this.kollelHalfDayCount = dashboard.KollelHalfDayCount
    this.yeshivaCount = dashboard.YeshivaCount
    this.kollelFullDayCount = dashboard.KollelFullDayCount
    this.payAllCount = dashboard.PayAllCount
    this.payKollelHalfDayCount = dashboard.PayKollelHalfDayCount
    this.payYeshivaCount = dashboard.PayYeshivaCount
    this.payKollelFullDayCount = dashboard.PayKollelFullDayCount
    this.rejectCount = dashboard.RejectCount
    this.duplicateCount = dashboard.DuplicateCount
    this.payAmount = dashboard.PayAmount
    this.kollelHalfDayRate = dashboard.KollelHalfDayRate
    this.yeshivaRate = dashboard.YeshivaRate
    this.kollelFullDayRate = dashboard.KollelFullDayRate
    this.payMonth = dashboard.PayMonth
    this.isMatchTeam = dashboard.IsMatchTeam
    this.notMatchKollelHalfDayCount = dashboard.NotMatchKollelHalfDayCount
    this.notMatchYeshivaCount = dashboard.NotMatchYeshivaCount
    this.notMatchKollelFullDayCount = dashboard.NotMatchKollelFullDayCount
    this.prevDifferences = dashboard.PrevDifferences

    makeAutoObservable(this)
  }
}
