import {makeAutoObservable, reaction, runInAction} from 'mobx'
import config, {systemObjects} from 'src/config'
import {MainStore} from 'src/store/MainStore'
import Institution from 'src/entities/Institution'
import CodeObject from 'src/entities/CodeObject'
import {UploadDocument} from 'src/entities/UploadDocument'
import Bank, {Branch} from 'src/entities/Bank'
import {downloaFile} from 'src/utils/export'
import City from 'src/entities/City'
import Audit from 'src/entities/Audit'
import {fformatDate, fformatTime} from 'src/utils/formatTime'
import {defaultMonth, getMonths} from 'src/utils/month'
import Month from 'src/entities/Month'

const defaultBranch = [
  {
    id: -1,
    label: 'אין סניפים לבנק זה',
    name: 'אין סניפים לבנק זה'
  } as Branch
]

export class InstitutionsStore {
  institutions?: Institution[]

  institutionsStatuses?: CodeObject[] = []

  institutionsTypes?: CodeObject[] = []

  studentsTypes?: CodeObject[] = []

  indificationTypes?: CodeObject[] = []

  currentInstitution?: Institution

  docsStatuses?: CodeObject[]

  docsCategories?: any[]

  loadingUploadDocType?: number

  banks?: Bank[]

  branches?: Branch[]

  isLoadingExportInstitutionsReport?: boolean

  isDeleteInstitution?: boolean

  isLoadingExportInstitutionsPaymentReport?: boolean

  paymetByMonth?: Institution[] = []

  selectedPaymetMonth?: Date

  cities?: City[]

  relevantMonths: Month[]

  constructor(readonly owner: MainStore) {
    this.relevantMonths = getMonths()
    makeAutoObservable(this)

    reaction(
      () => this.owner.loginStore.isMsalAuth,
      isAuth => {
        if (isAuth) {
          this.getInstitutionsStatuses()
          this.getInstitutionsTypes()
          this.getStudentTypes()
          this.getDocsStatuses()
          this.getDocsCategories()
          this.getIndificationTypes()
          this.getBanks()
          this.getCities()
          this.getInstitutions()
        }
      }
    )
  }

  setSelectedPaymetMonth = (selectedDate: Date | undefined | null) => {
    if (selectedDate === null) selectedDate = undefined
    this.selectedPaymetMonth = selectedDate
    if (this.selectedPaymetMonth === undefined) {
      this.paymetByMonth = []
    }
    this.getPaymentsByMonth()
  }

  clearPaymetByMonth = () => {
    this.paymetByMonth = []
  }

  setCurrentAudit = (audit?: Audit) => {
    if (this.currentInstitution) this.currentInstitution.audit = audit
  }

  getInstitutions = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Institutions`)
      .then((res: any) => {
        return res.json()
      })
      .then((data: Institution[]) => {
        if (data) {
          runInAction(() => {
            this.institutions = data.map(
              i =>
                new Institution(
                  i,
                  this.institutionsStatuses,
                  this.institutionsTypes,
                  this.studentsTypes
                )
            )
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  exportInstitutionsPaymentReport = async () => {
    this.setIsLoadingExportInstitutionsPaymentReport(true)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Institutions/CreateInstitutionPaymentReport`,
        {
          method: 'POST'
        }
      )

      const blob = await response.blob()

      if (blob) {
        downloaFile(blob, 'InstitutionsPayment')
        this.setIsLoadingExportInstitutionsPaymentReport(false)
      }
    } catch (error) {
      this.setIsLoadingExportInstitutionsPaymentReport(false)
      this.owner.setMessage('התהליך נכשל, נסה שוב.')
    }
  }

  getBanks = async () => {
    await this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/CodeTables/bank`)
      .then((res: any) => {
        return res.json()
      })
      .then(data => {
        runInAction(() => {
          this.banks = data.map((i: any) => new Bank(i))
        })
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getCities = () => {
    fetch(`${config.apiCityUrl}`)
      .then(response => response.json())
      .then(({result}: any) => {
        const cities: City[] = result.records.map((c: any) => ({
          id: c.שם_ישוב_לועזי.trim(),
          label: c.שם_ישוב
            .trim()
            .replace('(', '$')
            .replace(')', '(')
            .replace('$', ')')
        }))
        runInAction(() => {
          this.cities = cities
        })
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getPaymentsByMonth = () => {
    this.clearPaymetByMonth()
    if (this.selectedPaymetMonth) {
      this.owner.loginStore
        .fetchWithUser(
          `${config.apiUrl}/Institutions/paymetByMonth/${
            this.selectedPaymetMonth.getMonth() + 1
          }/${this.selectedPaymetMonth.getFullYear()}`
        )
        .then((res: any) => {
          return res.json()
        })
        .then((data: Institution[]) => {
          if (data) {
            runInAction(() => {
              this.paymetByMonth = data.map(
                i =>
                  new Institution(
                    i,
                    this.institutionsStatuses,
                    this.institutionsTypes,
                    this.studentsTypes
                  )
              )
            })
          } else {
            this.clearPaymetByMonth()
          }
        })
        .catch((e: any) => {
          console.log('error:', e)
        })
    }
  }

  setBranches = (bankId?: number) => {
    if (bankId) {
      const branches = this.banks?.find(b => b.id === bankId)?.bankBranches
      runInAction(() => {
        this.branches = branches?.length ? branches : defaultBranch
      })
    } else {
      runInAction(() => {
        this.branches = defaultBranch
      })
    }
  }

  updateAudit = async (auditData: Audit) => {
    const body = {
      ...auditData,
      auditTime: auditData.auditTime
        ? fformatTime(auditData.auditTime)
        : auditData.auditTime,
      auditDate: auditData.auditDate
        ? fformatDate(auditData.auditDate)
        : auditData.auditDate
    }

    await this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Institutions/auditData`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
      .then((res: any) => {
        return res.json()
      })
      .then(data => {
        if (data) {
          runInAction(() => {
            let instToUpdate = this.institutions?.find(
              i => i.id === auditData.institutionId
            )
            if (instToUpdate) {
              auditData.id = auditData.id === 0 ? data?.Id : auditData.id
              instToUpdate.audit = auditData
              this.setCurrentAudit(auditData)
            }
          })
        }
      })
      .catch(() => {
        this.owner.setMessage('השינויים לא נשמרו, נסה שוב.')
        throw new Error()
      })
  }

  getAudit = (institutionId: number) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Institutions/${institutionId}/Audits`)
      .then((res: any) => {
        return res.json()
      })
      .then((data: Audit[]) => {
        runInAction(() => {
          let instToUpdate = this.institutions?.find(
            i => i.id === institutionId
          )
          if (instToUpdate) {
            if (data && data.length > 0) {
              instToUpdate.audit = new Audit(data[0])
            }
          }
        })
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  updateInstitutionsStatus = (id: number, statusId: string) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Institutions/${id}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: statusId
      })
      .then((res: any) => {
        return res.json()
      })
      .then(data => {
        if (data) {
          runInAction(() => {
            let instToUpdate = this.institutions?.find(i => i.id === id)
            if (instToUpdate) {
              instToUpdate.updatedAt = new Date()
              instToUpdate.statusId = Number(statusId)
            }
            if (instToUpdate) {
              instToUpdate.updatedAt = new Date()
              instToUpdate.statusId = Number(statusId)
            }
          })
        }
      })
      .catch(() => {
        this.owner.setMessage('השינויים לא נשמרו, נסה שוב.')
      })
  }

  updateInstitutionsAssignee = (id: number, userId: string) => {
    return this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Institutions/${id}/assign`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: userId
      })
      .then((res: any) => {
        return res.json()
      })
      .then(data => {
        if (data) {
          runInAction(() => {
            let instToUpdate = this.institutions?.find(i => i.id === id)
            if (instToUpdate) {
              instToUpdate.assignUserId = Number(userId)
              instToUpdate.updatedAt = new Date()
            }
            if (instToUpdate) {
              instToUpdate.assignUserId = Number(userId)
              instToUpdate.updatedAt = new Date()
            }
          })
        }
      })
      .catch(() => {
        this.owner.setMessage('השינויים לא נשמרו, נסה שוב.')
        throw new Error()
      })
  }

  updateApprovePerson = (id: number, approvePersonId: string) => {
    return this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Institutions/${id}/ApprovePerson`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: approvePersonId
      })
      .then((res: any) => {
        return res.json()
      })
      .then(data => {
        if (data) {
          runInAction(() => {
            let instToUpdate = this.institutions?.find(i => i.id === id)
            if (instToUpdate) {
              instToUpdate.approvePersonId = Number(approvePersonId)
              instToUpdate.updatedAt = new Date()
            }
          })
        }
      })
      .catch(() => {
        this.owner.setMessage('השינויים לא נשמרו, נסה שוב.')
        throw new Error()
      })
  }

  updateMonthStatus = (id: number, month: string) => {
    return this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Institutions/${id}/monthStatus`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: month
      })
      .then((res: any) => {
        return res.json()
      })
      .then(data => {
        if (data) {
          runInAction(() => {
            let instToUpdate = this.institutions?.find(i => i.id === id)
            if (instToUpdate) {
              instToUpdate.monthStatus = Number(month)
              instToUpdate.updatedAt = new Date()
            }
          })
        }
      })
      .catch(() => {
        this.owner.setMessage('השינויים לא נשמרו, נסה שוב.')
        throw new Error()
      })
  }

  getInstitutionsStatuses = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.institutionsStatuses}`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.institutionsStatuses = data.map((i: any) => new CodeObject(i))
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getIndificationTypes = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.indificationTypes}`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.indificationTypes = data.map((i: any) => new CodeObject(i))
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getInstitutionsTypes = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.institutionsTypes}`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.institutionsTypes = data.map((i: any) => new CodeObject(i))
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getStudentTypes = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.studentTypes}`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.studentsTypes = data.map((i: any) => new CodeObject(i))
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  updateInstitution = async (updatedInstitution: any) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Institutions`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updatedInstitution)
        }
      )

      await response
      runInAction(() => {
        let instToUpdate = this.institutions?.find(
          i => i.id === updatedInstitution.id
        )
        if (instToUpdate) {
          Object.assign(instToUpdate, updatedInstitution)
          instToUpdate.updatedAt = new Date()
          this.setCurrentInstitution(
            this.institutions?.find(i => i.id === updatedInstitution.id)
          )
        }
      })
    } catch (error) {
      this.owner.setMessage('השינויים לא נשמרו, נסה שוב.')
    }
  }

  updateAssociation = async (editedObject: any) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Institutions/Association`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(editedObject)
        }
      )

      const data = await response.json()
      if (!data) {
        throw new Error('failed to update institution details')
      } else {
        runInAction(() => {
          let instToUpdate = this.institutions?.find(
            i => i.association?.id === editedObject.id
          )
          if (instToUpdate && instToUpdate.association) {
            Object.assign(instToUpdate.association, editedObject)
            instToUpdate.association.updatedAt = new Date()
            this.setCurrentInstitution(
              this.institutions?.find(
                i => i.association?.id === editedObject.id
              )
            )
          }
        })
      }
    } catch (error) {
      this.owner.setMessage('השינויים לא נשמרו, נסה שוב.')
    }
  }

  updatAuditor = async (editedObject: any, id?: number) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Institutions/${id}/Audit`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(editedObject)
        }
      )

      const data = await response.json()
      if (!data) {
        throw new Error('failed to update auditor')
      } else {
        runInAction(() => {
          let instToUpdate = this.institutions?.find(i => i.id === id)
          if (instToUpdate) {
            instToUpdate.auditNote = editedObject.auditNote
            instToUpdate.auditor = editedObject.auditor
            instToUpdate.updatedAt = new Date()
            this.setCurrentInstitution(instToUpdate)
          }
        })
      }
    } catch (error) {
      this.owner.setMessage('השינויים לא נשמרו, נסה שוב.')
    }
  }

  updateContact = async (updatedContact: any) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Institutions/Contact`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(updatedContact)
        }
      )

      const data = await response.json()
      if (!data) {
        throw new Error('failed to update contact details')
      } else {
        runInAction(() => {
          let instToUpdate = this.institutions?.find(
            i => i.contact?.id === updatedContact.id
          )
          if (instToUpdate && instToUpdate.contact) {
            Object.assign(instToUpdate.contact, updatedContact)
            instToUpdate.contact.updatedAt = new Date()
            this.setCurrentInstitution(
              this.institutions?.find(i => i.contact?.id === updatedContact.id)
            )
          }
        })
      }
    } catch (error) {
      this.owner.setMessage('השינויים לא נשמרו, נסה שוב.')
    }
  }

  updateNote = async (note: string) => {
    const id = this.currentInstitution?.id
    if (!id) return
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/Institutions/${this.currentInstitution?.id}/note`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(note)
        }
      )
      .then((res: any) => {
        return res.json()
      })
      .then(() => {
        runInAction(() => {
          let instToUpdate = this.institutions?.find(i => i.id === id)
          if (instToUpdate) instToUpdate.internalNote = note
          this.setCurrentInstitution(this.institutions?.find(i => i.id === id))
        })
      })
      .catch(() => {
        this.owner.setMessage('השינויים לא נשמרו, נסה שוב.')
      })
  }

  updateMatchTeamNote = async (note: string) => {
    const id = this.currentInstitution?.id
    if (!id) return
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Institutions/${id}/matchTeamNote`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(note)
      })
      .then((res: any) => {
        return res.json()
      })
      .then(() => {
        runInAction(() => {
          let instToUpdate = this.institutions?.find(i => i.id === id)
          if (instToUpdate) instToUpdate.matchTeamNote = note
          this.setCurrentInstitution(this.institutions?.find(i => i.id === id))
        })
      })
      .catch(() => {
        this.owner.setMessage('השינויים לא נשמרו, נסה שוב.')
      })
  }

  getInstitution = (id: string): any => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Institutions/${id}`)
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            let instToUpdate = this.institutions?.find(i => i.id === Number(id))
            if (instToUpdate) {
              Object.assign(
                instToUpdate,
                new Institution(
                  data,
                  this.institutionsStatuses,
                  this.institutionsTypes,
                  this.studentsTypes
                )
              )
              //  this.setCurrentInstitution(
              //    this.institutions?.find(i => i.id === Number(id))
              // )
            }
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  setCurrentInstitution = (inst: Institution | undefined) => {
    runInAction(() => {
      this.currentInstitution = inst
    })
    this.owner.studentsStore.setCurrentMonth(defaultMonth)
  }

  getInstitutionsDocs = async (institutionId: number) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Documents/Institution/${institutionId}`
      )
      const docs = await response.json()
      return docs?.length
        ? docs.map((d: any) => {
            return {...d, id: d.Id}
          })
        : []
    } catch (e) {
      console.log('error:', e)
      return []
    }
  }

  getDocsStatuses = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.documentStatuses}`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.docsStatuses = data.map((i: any) => new CodeObject(i))
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getDocsCategories = () => {
    return this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.documentCategories}`
      )
      .then((res: any) => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.docsCategories = data.map((i: any) => new CodeObject(i))
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  getNewDocumnet = async (docId: number) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Documents/${docId}`
      )
      const newDoc = await response.json()
      return newDoc
    } catch (e) {
      console.log('error:', e)
      return null
    }
  }

  setLoadingUploadDocType = (loadingUploadDocType?: number) => {
    this.loadingUploadDocType = loadingUploadDocType
  }

  addDocument = async (
    doc: UploadDocument,
    file: File,
    isDocsUpload: boolean = true
  ) => {
    this.setLoadingUploadDocType(doc.CategoryId)

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Documents`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(doc)
        }
      )
      const data = await response.json()

      if (!data) throw new Error()

      const isSuccess = await this.uploadToStage(doc, data.DocumentId, file)
      if (isSuccess) {
        if (doc.CategoryId === 6) {
          await this.importReport(data.ExcelReportId)
        } else if (doc.CategoryId === 1) {
          await this.owner.teamReportStore.importReport(data.TeamReportId)
          const {currentMonth} = this.owner.studentsStore
          this.owner.teamReportStore.getTeamReports(
            currentMonth.id,
            currentMonth.year
          )
        }

        if (isDocsUpload) return await this.getNewDocumnet(data.DocumentId)
        return null
      } else {
        this.owner.setMessage('  תקלה בעלאת הקובץ, נסה שוב.')
        await this.deleteDocument(data.DocumentId)

        return null
      }
    } catch {
      if (doc.CategoryId === 6) this.owner.studentsStore.setFailedStatus()
      console.error('Failed to save document.')
      return null
    } finally {
      this.setLoadingUploadDocType(undefined)
    }
  }

  importReport = async (reportId: number) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/StudentReports/${reportId}/import`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()
      if (data && this.currentInstitution) {
        const month = this.owner.studentsStore.currentMonth
        this.owner.studentsStore.getLastReport(month.id, month.year)
      }

      if (!data) {
        throw new Error()
      }
    } catch (error) {
      throw new Error('Failed to import students file.')
    }
  }

  uploadToStage = async (
    doc: UploadDocument,
    documentId: number,
    file: File
  ) => {
    try {
      const filePath = `${doc.InstitutionId}/${documentId}/${doc.Name}`

      const isSuccess = await this.owner.azureStorageStore.uploadFile(
        file,
        doc.Extension,
        filePath
      )
      if (isSuccess) {
        return true
      } else {
        console.log('Failed to upload doc:', doc.Name)
        return false
      }
    } catch (e) {
      console.log(e)
      return false
    }
  }

  deleteDocument = async (docId: number) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Documents/${docId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (!response.ok) {
        throw new Error()
      }
    } catch (error) {
      throw new Error('Failed to delete documnet.')
    }
  }

  setIsDeleteInstitution = (isLoading: boolean) => {
    this.isDeleteInstitution = isLoading
  }

  deleteInstitution = async (institutionId: number) => {
    this.setIsDeleteInstitution(true)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Institutions/${institutionId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (!response.ok) {
        throw new Error()
      }
      runInAction(() => {
        const newData =
          this.institutions?.filter(
            institution => institution.id !== institutionId
          ) || []
        this.institutions = [...newData]
      })
      this.setIsDeleteInstitution(false)
    } catch (error) {
      this.setIsDeleteInstitution(false)
      this.owner.setMessage('מחיקת המוסד נכשלה, נסה שוב.')
    }
  }

  setIsLoadingExportInstitutionsReport = (isLoading: boolean) => {
    this.isLoadingExportInstitutionsReport = isLoading
  }

  setIsLoadingExportInstitutionsPaymentReport = (isLoading: boolean) => {
    this.isLoadingExportInstitutionsPaymentReport = isLoading
  }

  exportInstitutionsReport = async () => {
    this.setIsLoadingExportInstitutionsReport(true)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Institutions/createInstitutionsReport`,
        {
          method: 'POST'
        }
      )

      const blob = await response.blob()

      if (blob) {
        downloaFile(blob, 'InstitutionsList')
        this.setIsLoadingExportInstitutionsReport(false)
      }
    } catch (error) {
      this.setIsLoadingExportInstitutionsReport(false)
      this.owner.setMessage('התהליך נכשל, נסה שוב.')
    }
  }
}
