import {
  Alert,
  Paper,
  Stack,
  Tabs,
  Button,
  Tooltip,
  IconButton,
  TextField,
  SelectChangeEvent,
  Select,
  MenuItem,
  Typography
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMemo, useState} from 'react'
import {useMainStore} from 'src/contexts/Main'
import DataGridWrapper from '../grid/DataGridWrapperNew'
import CuiLoading from '../custom/CuiLoading'
import {
  GridColDef,
  GridFilterModel,
  GridLogicOperator
} from '@mui/x-data-grid-pro'
import CuiTab from 'src/components/custom/CuiTab'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import {ReactComponent as CheckIcon} from 'src/images/ic_check.svg'
import Label, {LabelColor} from 'src/components/label'
import LabelCol from 'src/components/grid/columnTypes/LabelCol'
import StudentsToolBar from 'src/components/students/StudentsToolBar'
import Student, {StudentTab} from 'src/entities/Student'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import {grey} from '@mui/material/colors'
import ReportModal from 'src/components/students/ReportModal'
import FailedReportModal from 'src/components/students/FailedReportModal'
import {ExpandMore} from '@mui/icons-material'
import StudentTeamReportsMonth from './StudentTeamReportsMonth'

export interface RenderEditLabelCellProps {
  value: any
  values: any[]
  onChange: (event: SelectChangeEvent) => void
}

export const RenderEditLabelCell = ({
  value,
  values,
  onChange
}: RenderEditLabelCellProps) => {
  return (
    <Select
      fullWidth
      sx={{
        borderRadius: 0,
        height: '100%'
      }}
      value={value}
      onChange={e => {
        onChange(e)
      }}
      renderValue={val => {
        const lv = values?.find(v => v.id === val)
        console.log(lv)
        return (
          <LabelCol
            pointer
            text={lv?.description || 'בחר'}
            color={lv?.color || 'primary'}
          />
        )
      }}
    >
      <MenuItem value={0} key={-1}>
        <LabelCol pointer text={'בחר'} color={'primary'} />
      </MenuItem>
      {values?.map((v, i) => (
        <MenuItem value={JSON.parse(v.id)} key={i}>
          <LabelCol
            pointer
            text={v.description || 'בחר'}
            color={v.color || 'primary'}
          />
        </MenuItem>
      ))}
    </Select>
  )
}

interface RejectProps {
  student: Student
  setReject: React.Dispatch<React.SetStateAction<Student | undefined>>
}

const Reject = ({student, setReject}: RejectProps) => {
  return (
    <>
      {student.isReject ? (
        <Label color="error">נדחה</Label>
      ) : (
        <Button
          variant="outlined"
          onClick={() => {
            setReject(student)
          }}
        >
          דחיה
        </Button>
      )}
    </>
  )
}

const StudentsList = observer(() => {
  const {
    students,
    duplicates,
    rejects,
    studentStatuses,
    lastReport,
    rejectStudent,
    cancelStudentRejection,
    updateStudentStatus,
    rows,
    changeTab,
    selectedTab,
    currentMonth,
    setCurrentMonth
  } = useMainStore().studentsStore
  const successStudents = useMemo(
    () => students.filter(s => s.isNeedPay),
    [students]
  )
  const [reject, setReject] = useState<Student>()
  const [cancelReject, setCancelReject] = useState<number>()
  const [rejectComment, setRejectComment] = useState<string>('')
  const [isOpenReport, setIsOpenReport] = useState(false)

  const columns: GridColDef[] = [
    {
      field: 'indificationNo',
      headerName: 'ת.ז',
      type: 'string',
      align: 'right',
      sortable: true,
      filterable: true,
      flex: 1
    },

    {
      field: 'lastName',
      headerName: 'שם משפחה',
      type: 'string',
      align: 'right',
      sortable: true,
      filterable: true,
      flex: 1
    },
    {
      field: 'firstName',
      headerName: 'שם פרטי',
      type: 'string',
      align: 'right',
      sortable: true,
      filterable: true,
      flex: 1
    },

    {
      field: 'birthDate',
      headerName: 'תאריך לידה',
      type: 'date',
      align: 'right',
      disableColumnMenu: true,
      valueGetter: (_, row) => row?.birthDate,

      flex: 1
    },

    {
      field: 'learningShift',
      headerName: 'סדר נלמד',
      type: 'string',
      align: 'right',
      sortable: true,
      filterable: true,
      valueGetter: (value: any) => value?.description,

      flex: 1
    },
    {
      field: 'learnLocation',
      headerName: 'מקום לימוד',
      type: 'string',
      align: 'right',
      sortable: true,
      filterable: true,
      flex: 1
    },
    {
      field: 'scholarship',
      headerName: 'מלגה',
      type: 'string',
      align: 'right',
      sortable: true,
      filterable: true,
      flex: 1
    },

    {
      field: 'street',
      headerName: 'כתובת',
      align: 'right',
      disableColumnMenu: true,
      valueGetter: (_, row) => row.street + ' ' + row.houseNumber,
      flex: 1
    },
    {
      field: 'city',
      headerName: 'עיר',

      align: 'right',
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'phone',

      headerName: 'טלפון',
      align: 'right',
      disableColumnMenu: true,
      flex: 1
    },
    {
      field: 'isOffset',
      headerName: 'מושהה בשל החלטת בג"ץ',

      align: 'right',
      disableColumnMenu: true,
      sortable: true,
      filterable: true,
      valueFormatter: value => (value ? 'כן' : 'לא'),
      flex: 1
    },
    {
      field: 'datotRateId',
      headerName: 'הגדרת זכאות במשרד הדתות',
      type: 'string',
      align: 'right',
      sortable: true,
      filterable: true,

      flex: 1
    }
  ]

  const additionalColumns: GridColDef[] = [
    {
      field: 'isProblem',
      width: 10,
      headerName: '',
      align: 'right',
      renderCell: params =>
        params?.value === true ? (
          <Tooltip title=" הושעה ע'י בגץ ולא הושעה בטים">
            <IconButton>
              <WarningIcon color="error" />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )
    },
    {
      field: 'isMismatchDatot',
      width: 30,
      headerName: '',
      align: 'right',
      renderCell: params =>
        params?.value === true ? (
          <Tooltip title="מידע לא תואם קוד דתות">
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )
    },
    {
      field: 'isNeedPay',
      headerName: '',
      width: 30,
      align: 'right',
      renderCell: params =>
        params?.value === true ? (
          <Tooltip title="מוצע לתמיכה">
            <IconButton>
              <CheckIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        ),
      flex: 1
    },

    {
      field: 'isReject',
      headerName: '',
      filterable: true,
      valueFormatter: (isReject: any) => {
        if (isReject) return 'נדחה'
        return 'דחיה'
      },
      renderCell: (params: any) => (
        <Reject student={params?.row} setReject={setReject} />
      ),
      flex: 1
    }
  ]

  const dupliacteColumns: GridColDef[] = [
    {
      field: 'institutionName',
      headerName: 'שם המוסד',
      sortable: true,
      flex: 1
    }
  ]

  const rejectColumns: GridColDef[] = [
    {
      field: 'indificationNo',
      headerName: 'ת.ז',
      type: 'string',
      align: 'right',
      sortable: true,
      filterable: true,
      flex: 1
    },
    {
      field: 'lastName',
      headerName: 'שם משפחה',
      type: 'string',
      align: 'right',
      sortable: true,
      filterable: true,
      flex: 1
    },
    {
      field: 'firstName',
      headerName: 'שם פרטי',
      // width: 100,
      type: 'string',
      align: 'right',
      sortable: true,
      filterable: true,
      flex: 1
    },
    {
      field: 'createdAt',
      headerName: 'תאריך הדחיה',
      type: 'date',
      align: 'right',
      disableColumnMenu: true,
      // valueGetter: (_, row) => row?.birthDate,
      flex: 1
    },

    {
      field: 'comment',
      headerName: 'סיבת הדחיה',
      type: 'string',
      align: 'right',
      sortable: true,
      filterable: true,
      flex: 1
    },
    {
      field: 'remove',
      headerName: 'הסר מרשימת הדחיה',
      // width: 100,
      type: 'string',
      align: 'right',
      sortable: true,
      filterable: true,
      flex: 1,
      renderCell: (params: any) => (
        <Button
          variant="outlined"
          onClick={() => {
            setCancelReject(params.row.id)
          }}
        >
          הסר מרשימת הדחיה
        </Button>
      )
    }
  ]

  const status: GridColDef = {
    field: 'statusId',
    headerName: 'סטטוס',
    minWidth: 120,
    sortable: true,
    filterable: true,
    type: 'singleSelect',
    align: 'center',
    flex: 1,
    headerAlign: 'center',
    editable: true,
    renderEditCell: (params: any) => {
      return (
        <RenderEditLabelCell
          values={studentStatuses?.filter(v => v.id) || []}
          value={params.value || 0}
          onChange={e => {
            console.log('row', params.row)
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: e.target.value
            })
            updateStudentStatus(params.row, Number(e.target.value) || undefined)
          }}
        />
      )
    },
    renderCell: (params: any) => {
      const lv = studentStatuses
        ?.filter(v => v.id)
        ?.find(v => v.id === params.value)
      console.log(lv)
      return (
        <LabelCol
          pointer
          text={lv?.description || 'בחר'}
          color={(lv?.color || 'primary') as LabelColor}
          startIcon={<ExpandMore />}
        />
      )
    }
  }
  const [cols, setCols] = useState<GridColDef[]>([
    ...columns,
    ...additionalColumns
  ])

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: StudentTab
  ) => {
    changeTab(newValue)
    switch (newValue) {
      case StudentTab.All:
        setCols([...columns, ...additionalColumns])
        break
      case StudentTab.Approved:
        setCols([...columns, ...additionalColumns])
        break
      case StudentTab.Duplicate:
        setCols([...dupliacteColumns, ...columns, status])
        break
      case StudentTab.Rejected:
        setCols([...rejectColumns])
        break
      default:
        break
    }
  }

  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null)

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: GridLogicOperator.And
  } as GridFilterModel)

  return (
    <>
      {students && students.length > 0 ? (
        <>
          {lastReport && lastReport.statusId !== 4 && (
            <Alert
              severity="error"
              sx={{
                alignItems: 'center',
                '& .MuiAlert-message': {flexGrow: 1},
                '& .MuiAlert-action': {padding: 0},
                '& .MuiAlert-icon': {m: 0, pl: 1}
              }}
              action={
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => setIsOpenReport(true)}
                >
                  עידכון דו”ח
                </Button>
              }
            >
              {/* <Stack width={'100%'} justifyContent={'space-between'} alignItems={'center'} direction={'row'} spacing={1}> */}
              יש במערכת קובץ דו”ח תלמידים חדש. יש לבדוק ולאשר.
              {/* </Stack> */}
            </Alert>
          )}
          <Stack pt={1}>
            <StudentTeamReportsMonth />
          </Stack>

          <Stack direction="row" justifyContent="space-between">
            <Tabs
              sx={{
                borderRight: 0,
                marginLeft: 'auto' // Align tabs to the right
              }}
              value={selectedTab}
              onChange={handleTabChange}
            >
              <CuiTab
                labelColor="info"
                labelText={students.length}
                key={1}
                text="הכל"
                value={StudentTab.All}
              />
              <CuiTab
                labelColor="success"
                labelText={successStudents.length}
                key={2}
                text="מאושר"
                value={StudentTab.Approved}
              />
              <CuiTab
                labelColor="warning"
                labelText={duplicates.length}
                key={3}
                text="כפול"
                value={StudentTab.Duplicate}
              />
              <CuiTab
                labelColor="error"
                labelText={rejects.length}
                key={4}
                text="דחיה"
                value={StudentTab.Rejected}
                sx={{mr: 5}}
              />
            </Tabs>
          </Stack>
          <Paper sx={{height: '80vh'}}>
            <DataGridWrapper
              pagination
              getRowId={(row: any) => row.id}
              columns={cols}
              rows={rows}
              getRowClassName={params => {
                if (params.row.isProblem === true) return 'row-warning'

                return ''
              }}
              filterModel={filterModel}
              disableColumnMenu
              onFilterModelChange={(newValue: GridFilterModel) => {
                setFilterModel(newValue)
              }}
              slots={{
                toolbar: StudentsToolBar
              }}
              slotProps={{
                panel: {
                  anchorEl: filterButtonEl
                },
                baseSelect: {native: false, defaultValue: ''},
                toolbar: {
                  setFilterButtonEl: setFilterButtonEl,
                  setFilterModel: setFilterModel,
                  setIsOpenReport: setIsOpenReport,
                  month: currentMonth,
                  setMonth: setCurrentMonth
                },
                filterPanel: {
                  filterFormProps: {
                    logicOperatorInputProps: {
                      variant: 'outlined',
                      size: 'small'
                    },
                    columnInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {mt: 'auto'}
                    },
                    operatorInputProps: {
                      variant: 'outlined',
                      size: 'small',
                      sx: {mt: 'auto'}
                    },
                    valueInputProps: {
                      InputComponentProps: {
                        variant: 'outlined',
                        size: 'small'
                      }
                    }
                  },
                  sx: {
                    '& .MuiDataGrid-panelFooter': {
                      justifyContent: 'flex-start',
                      px: 2.5
                    },
                    '& .MuiDataGrid-panelFooter .MuiButton-root:last-of-type': {
                      color: (theme: any) => theme.palette.primary.main,
                      backgroundColor: 'transparent',
                      '&:hover': {
                        backgroundColor: 'rgba(0, 171, 85, 0.08)'
                      }
                    },
                    '& .MuiDataGrid-filterForm': {p: 2},
                    '& .MuiDataGrid-filterFormLogicOperatorInput': {
                      mr: 1,
                      width: 75
                    },
                    '& .MuiDataGrid-filterFormColumnInput': {mr: 1, width: 150},
                    '& .MuiDataGrid-filterFormOperatorInput': {
                      mr: 1,
                      width: 150
                    },
                    '& .MuiDataGrid-filterFormValueInput': {width: 280},
                    '& .MuiDataGrid-filterFormDeleteIcon': {
                      justifyContent: 'center'
                    }
                  }
                }
              }}
              sx={{
                '& .MuiDataGrid-cell': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }
              }}
            />
          </Paper>
          {reject && (
            <CuiAlertDialog
              open
              title="הוספה לרשימת דחיה"
              okClick={() => {
                rejectStudent(reject, rejectComment)
              }}
              close={() => {
                setReject(undefined)
                setRejectComment('')
              }}
              okButtonText="אישור"
              disableOkButton={!rejectComment}
              showCancel
              body={
                <TextField
                  fullWidth
                  onChange={e => {
                    setRejectComment(e.target.value)
                  }}
                  rows={2}
                  placeholder="סיבת הדחיה"
                  multiline
                />
              }
            />
          )}
          {cancelReject && (
            <CuiAlertDialog
              open
              title="הסרה מרשימת דחיה"
              okClick={() => {
                cancelStudentRejection(cancelReject)
              }}
              close={() => {
                setCancelReject(undefined)
              }}
              okButtonText="אישור"
              showCancel
              body={
                <Typography variant="body1" color={grey[700]} align="center">
                  {' '}
                  האם אתה בטוח שברצונך להסיר את התלמיד
                  <br /> מרשימת הדחיה?
                </Typography>
              }
            />
          )}
          {isOpenReport &&
            lastReport &&
            (lastReport.statusId === 2 ? (
              <ReportModal
                isOpen={isOpenReport}
                onClose={() => setIsOpenReport(false)}
              />
            ) : [3, 5].includes(lastReport.statusId) ? (
              <FailedReportModal
                fileName={lastReport.document?.name ?? ''}
                url={lastReport.document?.url ?? ''}
                isOpen={isOpenReport}
                onClose={() => setIsOpenReport(false)}
              />
            ) : (
              <></>
            ))}
        </>
      ) : (
        <CuiLoading />
      )}
    </>
  )
})

export default StudentsList
