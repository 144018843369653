import {makeAutoObservable, reaction, runInAction} from 'mobx'
import config, {systemObjects} from 'src/config'
import {MainStore} from 'src/store/MainStore'
import CodeObject from '../entities/CodeObject'
import Payment, {PaymentTab} from 'src/entities/payment'
import {downloaFile} from 'src/utils/export'
import {MonthlyPaymentSummary} from 'src/entities/MonthlyPaymentSummary'
import {CalcPayment} from 'src/entities/CalcPayment'

export class PaymentsStore {
  payments?: Payment[]

  calcPayments?: CalcPayment[]

  paymentsStatuses: CodeObject[] = []

  isLoadingExportReport?: boolean

  isLoadingSendPaymentEmail?: boolean

  isInvalidPaymentReportId?: boolean

  isDeletePayment?: boolean = false

  selectedTab: PaymentTab = PaymentTab.CalcPayment

  monthlyPaymentsSummary: MonthlyPaymentSummary[] = []

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    reaction(
      () => this.owner.loginStore.isMsalAuth,
      isAuth => {
        if (isAuth) {
          this.getPaymentsStatuses()
          this.getPayments()
        }
      }
    )
    reaction(
      () => this.owner.institutionStore.currentInstitution,
      currentInstitution => {
        if (currentInstitution) {
          this.getCalcPayments()
          this.getPayments()
        }
      }
    )
  }

  setIsLoadingSendPaymentEmail = (isLoading: boolean) => {
    this.isLoadingSendPaymentEmail = isLoading
  }

  setIsInvalidPaymentReportId = (isInvalid: boolean) => {
    this.isInvalidPaymentReportId = isInvalid
  }

  setIsDeletePayment = (isDeletePayment: boolean) => {
    this.isDeletePayment = isDeletePayment
  }

  setSelectedTab = (tab: PaymentTab) => {
    this.selectedTab = tab
  }

  setCalcPayments(payments: CalcPayment[]) {
    this.calcPayments = payments
    this.calcPayments?.forEach(calc => {
      calc.setAllpayments(this.calcPayments ?? [])
    })
  }

  private mapToServerModel(calc: any) {
    return {
      Id: calc.id,
      InstitutionId: calc.institutionId,
      Month: calc.month,
      MonthString: calc.monthString,
      Year: calc.year,
      BaseOnMonths: calc.baseOnMonths.map((baseOn: any) => ({
        Month: baseOn?.month,
        MonthString: baseOn?.monthString,
        IsDefault: baseOn?.isDefault,
        CalcPayAmount: baseOn?.calcPayAmount
      })),
      PaymentAmount: calc.paymentAmount,
      AllPayments: calc.allPayments
    }
  }

  setDefaultMonth = (calcPaymentId: number, month: number) => {
    const updatedPayments =
      this.calcPayments?.map(calc =>
        calc.id === calcPaymentId
          ? new CalcPayment(
              this.mapToServerModel({
                ...calc,
                baseOnMonths: calc.baseOnMonths.map(baseOn => ({
                  ...baseOn,
                  isDefault: baseOn.month === month
                }))
              }),
              this.calcPayments ?? []
            )
          : calc
      ) || []

    this.setCalcPayments(updatedPayments)
  }

  getPayments = async () => {
    try {
      const res = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Payments`
      )
      const data = await res.json()
      if (data) {
        runInAction(() => {
          this.payments = data.map((d: any) => {
            return new Payment(d, this.paymentsStatuses)
          })
        })
      }
    } catch (e) {
      console.log('error:', e)
    }
  }

  private getIsDefault(
    existingPayment: CalcPayment | undefined,
    month: number,
    baseOnDefault: boolean
  ) {
    const prevIsDefault = existingPayment?.baseOnMonths.find(
      b => b.month === month
    )?.isDefault
    return prevIsDefault !== undefined ? prevIsDefault : baseOnDefault
  }

  getCalcPayments = async (usePrevious: boolean = false) => {
    try {
      const res = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Payments/CalcPayments/${this.owner.institutionStore.currentInstitution?.id}`
      )
      const data = await res.json()
      if (data) {
        runInAction(() => {
          let allPayments: CalcPayment[] = []
          data.forEach((d: any, index: number) => {
            const existingPayment = this.calcPayments?.find(
              c => c.id === index + 1
            )

            allPayments.push(
              new CalcPayment(
                {
                  ...d,
                  Id: index + 1,
                  BaseOnMonths: !usePrevious
                    ? d.BaseOnMonths
                    : d.BaseOnMonths.map((baseOn: any) => ({
                        ...baseOn,
                        IsDefault: this.getIsDefault(
                          existingPayment,
                          baseOn.Month,
                          baseOn.IsDefault
                        )
                      }))
                },
                allPayments
              )
            )
          })
          this.calcPayments = allPayments
        })
      }
    } catch (e) {
      console.log('error:', e)
    }
  }

  get decidedPayments() {
    return this.payments?.filter(p => p.statusId === 1) || []
  }

  get decidedPaymentsCount() {
    return this.decidedPayments.length
  }

  get decidedPaymentsSum() {
    return this.decidedPayments.reduce(
      (sum, payment: any) => sum + payment?.amount || 0,
      0
    )
  }

  getPaymentsStatuses = () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/CodeTables/${systemObjects.paymentStatuses}`
      )
      .then(res => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.paymentsStatuses = data.map((i: any) => new CodeObject(i))
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  createPayment = (payment: any) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Payments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payment)
      })
      .then(res => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          runInAction(() => {
            this.payments?.push(
              new Payment(
                {
                  Id: data?.Id,
                  Amount: payment.amount,
                  StatusId: 1,
                  Month: payment.month,
                  Year: payment.year,
                  Comment: payment.comment,
                  InstitutionId: payment.institutionId,
                  CreatedAt: new Date(),
                  CreatedBy: payment.createdBy
                },
                this.paymentsStatuses
              )
            )
            this.getCalcPayments(true)
          })
        }
      })
      .catch((e: any) => {
        console.log('error: ', e)
        this.owner.setMessage('ההחלטה לא נשמרה, נסה שוב.')
      })
  }

  sendPaymentEmail = async (paymentReportId: number) => {
    return this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/Payments/SendPaymentEmails/${paymentReportId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(res => {
        if (res.status === 200) {
          this.setIsInvalidPaymentReportId(false)
          this.getPayments()
          return true
        }
      })
      .catch((e: any) => {
        if (e.status === 404) {
          this.setIsInvalidPaymentReportId(true)
          return false
        }
        console.log('error: ', e)

        this.owner.setMessage('ארעה שגיאה בשליחת הדו"ח, נסה שוב.')
        return false
      })
      .finally(() => {
        this.setIsLoadingSendPaymentEmail(false)
      })
  }

  updatePayment = (payment: any) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Payments`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payment)
      })
      .then(res => {
        return res.json()
      })
      .then(() => {
        runInAction(() => {
          const paymentToUpdate = this.payments?.find(i => i.id === payment.id)
          if (paymentToUpdate) {
            Object.assign(
              paymentToUpdate,
              new Payment(
                {
                  Id: payment.id,
                  Amount: payment.amount,
                  Month: payment.month,
                  Year: payment.year,
                  Comment: payment.comment,
                  InstitutionId: payment.institutionId,
                  CreatedAt: paymentToUpdate.createdAt,
                  StatusId: paymentToUpdate.statusId
                },
                this.paymentsStatuses
              )
            )
          }
        })
      })
      .catch((e: any) => {
        console.log('error: ', e)
        this.owner.setMessage('ההחלטה לא נשמרה, נסה שוב.')
      })
  }

  getDashboardPaymentsByMonth = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Payments/MonthlySummary`)
      .then((res: any) => {
        return res.json()
      })
      .then((data: MonthlyPaymentSummary[]) => {
        if (data) {
          runInAction(() => {
            this.monthlyPaymentsSummary = data.map(
              monthSummer => new MonthlyPaymentSummary(monthSummer)
            )
          })
        }
      })
      .catch((e: any) => {
        console.log('error:', e)
      })
  }

  updatePaymentStatus = async (payment: Payment, statusId: number) => {
    const prevStatus = payment.statusId
    if (prevStatus === statusId) return

    payment.setStatus(statusId)

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Payments/${payment.id}/status`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(statusId)
        }
      )
      const data = await response.json()
      if (data) {
      } else {
        payment.setStatus(prevStatus)
        throw new Error()
      }
    } catch (error) {
      this.owner.setMessage('התהליך נכשל, נסה שוב.')
    }
  }

  setIsLoadingExportReport = (isLoading: boolean) => {
    this.isLoadingExportReport = isLoading
  }

  exportReport = async () => {
    this.setIsLoadingExportReport(true)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Payments/createPaymentReport`,
        {
          method: 'POST'
        }
      )

      const blob = await response.blob()

      if (blob) {
        downloaFile(blob, 'PaymentsList')
        await this.getPayments()
        this.setIsLoadingExportReport(false)
      }
    } catch (error) {
      this.setIsLoadingExportReport(false)
      this.owner.setMessage('התהליך נכשל, נסה שוב.')
    }
  }

  deletePayment = async (paymentId: number) => {
    this.setIsDeletePayment(true)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Payments/${paymentId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (!response.ok) {
        throw new Error()
      }
      runInAction(() => {
        const newData =
          this.payments?.filter(payment => payment.id !== paymentId) || []
        this.payments = [...newData]
        return true
      })
      this.setIsDeletePayment(false)
    } catch (error) {
      this.setIsDeletePayment(false)
      this.owner.setMessage('מחיקת התשלום נכשלה, נסה שוב.')
    }
    return false
  }
}
