import {Box, Card, MenuItem, Select, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import DataGridWrapper from '../grid/DataGridWrapperNew'
import {
  GridColDef,
  GridFilterModel,
  GridLogicOperator
} from '@mui/x-data-grid-pro'
import {useMainStore} from '../../contexts/Main'
import {observer} from 'mobx-react-lite'
import {BaseOnResource, CalcPayment} from 'src/entities/CalcPayment'

const CalPaymentGrid = observer(() => {
  const {calcPayments, setDefaultMonth, getCalcPayments} =
    useMainStore().paymentsStore

  useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      try {
        if (isMounted) {
          getCalcPayments()
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching data:', error)
        }
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [getCalcPayments])

  const [filterModel, setFilterModel] = useState({
    items: [],
    linkOperator: GridLogicOperator.And
  } as GridFilterModel)

  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null)

  const columns: GridColDef[] = [
    {
      field: 'monthString',
      headerName: 'חודש',
      headerAlign: 'center',
      minWidth: 100,
      type: 'string',
      align: 'center',
      flex: 1
    },

    {
      field: 'defaultMonth',
      headerName: 'החלטה לפי חודש',
      minWidth: 150,
      editable: false,
      filterable: true,
      align: 'center',
      flex: 1,
      headerAlign: 'center',

      valueGetter: (_, row) =>
        (row as CalcPayment)?.defaultMonth?.monthString ?? '',
      valueFormatter: (_, row) => row?.defaultMonth?.monthString,

      renderCell: (params: any) => {
        const {row} = params
        const isEditable = (row?.baseOnMonths?.length || 0) > 1

        return isEditable ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Select
              variant="standard"
              value={row.defaultMonth?.month || ''}
              disableUnderline
              sx={{
                width: '100%',
                height: '100%',
                borderRadius: 0
              }}
              onChange={e => {
                const newValue = Number(e.target.value)
                setDefaultMonth(row.id, newValue)
              }}
              fullWidth
            >
              {row?.baseOnMonths?.map((baseOn: BaseOnResource) => (
                <MenuItem key={baseOn.month} value={baseOn.month}>
                  {baseOn.monthString}
                </MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%'
            }}
          >
            <Typography>
              {params?.row?.defaultMonth?.monthString || ''}
            </Typography>
          </Box>
        )
      },

      type: 'singleSelect'
    },

    {
      field: 'paymentAmount',
      headerName: 'שולם',
      headerAlign: 'center',
      type: 'number',
      minWidth: 120,
      align: 'center',
      flex: 1,
      valueFormatter: (_, row) => {
        const formattedAmount = new Intl.NumberFormat('en-US').format(
          row.paymentAmount
        )
        return `${formattedAmount} ש"ח`
      }
    },
    {
      field: 'calcPayAmountForDefaultMonth',
      headerName: 'חושב לתשלום',
      headerAlign: 'center',
      type: 'number',
      minWidth: 120,
      align: 'center',
      flex: 1,
      valueFormatter: (_, row) => {
        if (!row.calcPayAmountForDefaultMonth) {
          return `${0} ש"ח`
        }
        const formattedAmount = new Intl.NumberFormat('en-US').format(
          row.calcPayAmountForDefaultMonth
        )
        return `${formattedAmount} ש"ח`
      }
    },
    {
      field: 'differences',
      headerName: 'הפרשים',
      headerAlign: 'center',
      type: 'number',
      minWidth: 120,
      align: 'center',
      flex: 1,
      valueFormatter: (_, row) => {
        if (!row.differences) {
          return ''
        }
        const formattedAmount = new Intl.NumberFormat('en-US').format(
          row.differences
        )
        return `${formattedAmount} ש"ח`
      }
    },
    {
      field: 'totalPaymentWithDifferences',
      headerName: 'לתשלום כולל הפרשים',
      headerAlign: 'center',
      type: 'number',
      minWidth: 140,
      align: 'center',
      flex: 1,
      valueFormatter: (_, row) => {
        if (!row.totalPaymentWithDifferences) {
          return ''
        }
        const formattedAmount = new Intl.NumberFormat('en-US').format(
          row.totalPaymentWithDifferences
        )
        return `${formattedAmount} ש"ח`
      }
    }
  ]

  return (
    <>
      <Card sx={{minHeight: '100%', p: 2, m: 1.5}}>
        {calcPayments && calcPayments.length > 0 && (
          <DataGridWrapper
            columns={columns}
            rows={calcPayments || []}
            getRowId={row => row.id}
            hideFooter
            isCellEditable={(params: any) => {
              return params.row?.baseOnMonths?.length > 1
            }}
            disableColumnMenu
            filterModel={filterModel}
            onFilterModelChange={(newValue: GridFilterModel) => {
              setFilterModel(newValue)
            }}
            slotProps={{
              panel: {
                anchorEl: filterButtonEl,
                placement: 'bottom'
              },
              baseSelect: {native: false, defaultValue: ''},
              toolbar: {
                setFilterButtonEl: setFilterButtonEl,
                setFilterModel: setFilterModel
              },
              filterPanel: {
                filterFormProps: {
                  logicOperatorInputProps: {
                    variant: 'outlined',
                    size: 'small'
                  },
                  columnInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: {mt: 'auto'}
                  },
                  operatorInputProps: {
                    variant: 'outlined',
                    size: 'small',
                    sx: {mt: 'auto'}
                  },
                  valueInputProps: {
                    InputComponentProps: {
                      variant: 'outlined',
                      size: 'small'
                    }
                  }
                },
                sx: {
                  '& .MuiDataGrid-panelFooter': {
                    justifyContent: 'flex-start',
                    px: 2.5
                  },
                  '& .MuiDataGrid-panelFooter .MuiButton-root:last-of-type': {
                    color: (theme: any) => theme.palette.primary.main,
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 171, 85, 0.08)'
                    }
                  },
                  '& .MuiDataGrid-filterForm': {p: 2},
                  '& .MuiDataGrid-filterFormLogicOperatorInput': {
                    mr: 1,
                    width: 75
                  },
                  '& .MuiDataGrid-filterFormColumnInput': {mr: 1, width: 150},
                  '& .MuiDataGrid-filterFormOperatorInput': {
                    mr: 1,
                    width: 150
                  },
                  '& .MuiDataGrid-filterFormValueInput': {width: 280},
                  '& .MuiDataGrid-filterFormDeleteIcon': {
                    justifyContent: 'center'
                  },
                  '& .MuiDataGrid-main': {
                    direction: 'rtl',
                    overflowX: 'auto'
                  },
                  '& .MuiDataGrid-scrollbar': {right: 'unset', left: '0'},
                  '& .MuiDataGrid-virtualScroller': {direction: 'ltr'}
                }
              }
            }}
          />
        )}
      </Card>
    </>
  )
})

export default CalPaymentGrid
